<template>
  <div>
       <!-- 创建 -->
        <div style="width:500px;margin:0 auto;background:#fff;">
            <div style="text-align:left;">

               <el-row>
                    <p style="width:100%;font-size:16px;line-height:40px;height:40px;">名称：</p>
                    <el-input
                    placeholder="请输入内容"
                    v-model="saveData.name"
                    clearable>
                    </el-input>
               </el-row>

                <el-row>
                    <p style="width:100%;font-size:16px;line-height:40px;height:40px;margin-top:20px;">允许小数</p>
                    <div>
                        <el-radio-group v-model="saveData.decimals_allowed">
                            <el-radio-button label="true">允许</el-radio-button>
                            <el-radio-button label="false">禁止</el-radio-button>
                        </el-radio-group>
                    </div>
                </el-row>
                <!-- <el-row>
                    <p style="width:100%;font-size:16px;line-height:40px;height:40px;">分享有效时间</p>
                    截至到：<el-date-picker :format="'yyyy-MM-dd'" type="datetime" placeholder="请选择日期" style="width:130px;" suffix-icon="el-icon-date" :disabled="date_forever" v-model="saveData.effective_time" :min="1" :max="1000" label="分享有效时间" size="mini"></el-date-picker> <span style="color:blue;display:inline-block;width:40px;text-align:center;font-size:18px;"> 或 </span>
                    <el-checkbox v-model="date_forever" label="永久有效" border size="mini"></el-checkbox>
                </el-row>
                <el-row>
                    <p style="width:100%;font-size:16px;line-height:40px;height:40px;margin-top:20px;">可以复制次数</p>
                    <el-input-number :disabled="times_forever" v-model="saveData.share_times" :min="1" :max="100000" label="分享有效时间" size="mini"></el-input-number> 次<span style="color:blue;display:inline-block;width:40px;text-align:center;font-size:18px;"> 或 </span>
                    <el-checkbox v-model="times_forever" label="不限次数" border size="mini"></el-checkbox>
                </el-row>

                <el-row>
                    <p style="width:100%;font-size:16px;line-height:40px;height:40px;margin-top:20px;">分享状态</p>
                        <div>
                            <el-radio-group v-model="saveData.status">
                            <el-radio-button label="1">开启</el-radio-button>
                            <el-radio-button label="2">关闭</el-radio-button>
                            </el-radio-group>
                        </div>
                </el-row>


                <el-row v-if="saveComplete || shareId>0">
                    <p style="width:100%;font-size:18px;line-height:40px;height:40px;margin-top:20px;">分享码:
                    </p>
                    <div v-for="(item,key) in codeList" :key="key">
                        <span><span style="display:inline-block;margin-right:20px;">分享码{{key+1}}</span>
                            <span>{{item.code}}</span>
                            <el-button type="text" @click="copy(item.code,item)" style="color:blue;cursor:pointer;display:inline-block;margin-left:20px;">
                                复制
                                <i class="el-icon-success" style="color:green;" v-if="item.status==88"></i>
                            </el-button>
                            
                        </span>
                    </div>
                </el-row> -->
            </div>

            <div slot="footer" class="dialog-footer" style="text-align:center;height:60px;line-height:60px;margin-top:60px;">
                <el-button type="primary"  @click="save();">保 存</el-button>
                <!-- <el-button type="primary" v-if="saveComplete" @click="exit();">关 闭</el-button> -->
            </div>
        </div> 
  </div>
</template>

<script>
import * as dayjs from "dayjs";
import tools from "../../../extends/tools";
import apis from "../../common/js/api2";

export default {
    props:{
        id:{
            type:Number,
            default:0
        }
    },
    data(){
        return {
            saveData:{
                decimals_allowed:true,
                name:''
            }
        }
    },
    methods:{
        copy(code,item){

        },
        beforeUpload(){
            this.uploadLoading = true;
        },
        save(){
            //判断是修改还是新增
            if(this.id > 0){
                this.saveData.id = this.id;
                //保存  
                apis.goodsUnitUpdate(this.saveData).then((res)=>{
                    if(res.code != 200){
                        this.$message({
                            type:'error',
                            message:res.msg
                        });
                        return;
                    }
                    this.$message({
                        type:'success',
                        message:'保存成功'
                    });
                    
                    this.saveData.name = '';
                    //通知列表页更新数据
                    this.fleshList();
                }).catch((err)=>{
                    console.log(err);
                    this.$message('网络不通，请稍候重试-');
                });                
                
                return;
            }

            //新增
            apis.addGoodsUnit(this.saveData).then((res)=>{
                if(res.code != 200){
                    this.$message({
                        type:'error',
                        message:res.msg
                    });
                    return;
                }
                //显示分享码
                this.saveComplete = true;
                this.$message({
                    type:'success',
                    message:'添加成功'
                });
                //通知列表页更新数据
                this.fleshList();
            }).catch((err)=>{
                console.log(err);
                this.$message('网络不通，请稍候重试-');
            });

        },
        exit(){
            this.$emit('shareExit')
        },
        fleshList(){
            this.$emit('fleshList')
        }
    },
    created(){
        //判断是修改还是新增
        if(this.id > 0){
            //初始化修改数据
            apis.goodsUnitDetail({id:this.id}).then((res)=>{
               res.data.password = ''; 
                this.saveData = res.data;
                this.saveData.id = this.id;
                this.imageUrl = this.baseUrl + res.data.portrait;
            }).catch((err)=>{
                console.log(err);
                this.$message('网络错误，获取数据失败');
            });
        }
    }
}
</script>

<style>
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 78px;
    height: 78px;
    line-height: 78px;
    text-align: center;
  }
  .avatar {
    width: 78px;
    height: 78px;
    display: block;
  }
</style>